// Import React
import React from 'react';

export default function Vietnam() {
    return (
        <article className="content app-body">
        
            <h1 className="center-div heading">Comparative Analysis of Minh Mạng and Nguyễn Thị Bình</h1>

            <div className="center-div">
                <img className='article-img' src="../images/vietnamMap.png" alt='Map of Dai Nam Vietname from the 1820s'/>
                <figcaption>
                    Map of <a 
                                href="https://commons.wikimedia.org/wiki/File:Nguyen_Dynasty,_administrative_divisions_map_(1838).svg" 
                                rel='noreferrer' 
                                target='_blank'
                    >Đại Nam</a> in 1838 during the rule of Minh Mạng.
                </figcaption>
            </div>

            <div className="center-div">
                <figcaption>
                    <a href="../index.html">Donovan Frazier</a> | Oct 18, 2022 | Updated Feb 25, 2023
                </figcaption>
            </div>

            <div className="center-div">
                <p>
                    For this historical analysis, I will be comparing Minh Mạng the second emperor of the 
                    Nguyễn dynasty to Nguyễn Thị Bình, the granddaughter of peaceful Phan, Phan Châu Trinh. 
                    They each pursued nationalist agendas under very different circumstances. For instance, Minh 
                    Mạng predates French colonialism thus his programs had a very different goal than the 
                    postcolonial era. Minh Mạng was focused on crafting his Đại Nam empire under a Confucian
                    basis during an era of Vietnamese independence from China. Nguyễn Thị Bình on the other 
                    hand was late in the anti-colonial movements and used the momentum already established to 
                    remove imperial powers from Vietnam. She worked to centralize the state under a communist 
                    government centered in the north which would gain a strong alliance with China and the Soviet 
                    Union which would give the newly united Vietnam international recognition. Outside of 
                    building strong ties between the north and south, she also became an international figure most 
                    known for representing the National Liberation Front or Việt Cộng during the Paris Peace 
                    Accords which would end the American War within the Second Indochina War. Both figures of 
                    course had very different actions given they had very different circumstances, but both of their 
                    agency was centered on a more central, singular Vietnamese state but the largest distinction was 
                    that Nguyễn Thị Bình was from the south and humanized the struggle of the south which was far more 
                    effective at deescalating already existing conflict than Minh Mạng who escalated tension 
                    between the two halves of the state.
                </p>

                <div className="center-div">
                    <img src="../images/minhMang.gif" alt='Emperor Minh Mạng sitting on his throne.'/>
                    <figcaption>
                        Fig 1 - Portrait of Minh Mạng from Journal of an Embassy to the Courts of Siam and Cochin-China, exhibiting a view of the 
                        actual State of these Kingdoms by John Crawfurd (1783-1868), London, 1828.
                    </figcaption>
                </div>

                <p>
                    Minh Mạng as mentioned before was the second emperor in the Nguyễn dynasty and was 
                    the successor to Gia Long, the first emperor of the Nguyễn dynasty. Minh Mạng came into 
                    power, Vietnam was still shattered and much of his agency was bent on fixing this shattered state 
                    and build a more centralized one
                    according to Goscha. He did unify the country under the Đại 
                    Nam empire which had its capital in Huế city6 which would be the imperial center while other 
                    regions were subject to this central government. As Goscha notes, Minh Mạng still left behind a 
                    fractured country in spite of spending two decades centralizing the state. Much of that time was 
                    spend methodically reforming the religiously tolerant south led by Lê Văn Duyệt. Minh Mạng
                    pursued persecuting Catholics centered around Sài Gòn of which was seen as a foreign religion 
                    that didn’t belong in Vietnam. His goal was to push out the foreign influences and domestic 
                    influence of Confucianism even if that meant isolationism of the state. On a government side, 
                    Minh Mạng sent northern scholars and military generals to the south to assimilate the south into 
                    the north’s and center’s cultural structure, seeing the scholars, leaders of government, as being 
                    lazy and unfit which displayed his overt disdain for the south and stripped away its tolerant 
                    laws built up by Lê Văn Duyệt which resulted in small-scale revolts that were put down before 
                    they could expand into war. Scholars were supposed to reeducate the south, but data showed 
                    that southerners were less likely to pass the national confusion exams than northerners under 
                    Minh Mạng’s reign which displays that this goal was no reached. These measures show that the assimilation 
                    of the south was aggressive but not violent and represented and overall tense peace.
                    The central government was asserting its power over the other regions, especially the south and 
                    Cambodia. These nationalist policies ultimately resulted in the formation of the Đại Nam which 
                    was a more expansive empire than its predecessor.
                </p>

                    
                <p>
                    Nguyễn Thị Bình got her start organizing student protests in the 1950s in Sài Gòn against 
                    Americans stationed in the city which resulted in her being jailed for three years. She is also 
                    one of the founding members of the National Liberation Front, often called the Việt Cộng. Just 
                    as a side note, the Việt Cộng was often a name given to any communist south Vietnamese but 
                    Nguyễn Thị Bình was in the actual organized faction that would later be represented at the Paris 
                    Peace Accords. Her nationalist policies varied depending on the position of leadership she had 
                    but always revolved around forming a singular socialist state. While she was appointed as the 
                    foreign minister of the Provisional Revolutionary Government of South Vietnam, a separate 
                    government from the Republic of Vietnam, she fostered relationships with the Soviet Union and 
                    People’s Republic of China so that the Provisional government would gain diplomatic
                    recognition and be seen as a legitimate government internationally as the true south’s 
                    government and was a success. For this, was known as a trusted communist and was rewarded 
                    and tasked with incorporating the south into the Democratic Republic of Vietnam. Her tactics 
                    unlike Minh Mạng, were built on humanizing the south, particularly women and family structure 
                    as it was altered by the Second Indochina War. She produced publications and short films while 
                    a part of the Women’s Union of Liberation of South Vietnam and advocated primary on peaceful basis to end the war. 
                    As noted before, she also negotiated the Paris Peace Accords on the behalf 
                    of the National Liberation Front. Her nationalist activities spanned from her protests in the 50s 
                    well into her terms as the vice president between 1992 and 2002 which is roughly double the 
                    time Minh Mạng had reigned in the early 1820s to 1840s. In all of these activities, she worked to 
                    build closer ties between the north and south so Vietnam would be seen as one state as opposed 
                    to the current two warring states of the Second Indochina War.
                </p>

                <div className="center-div">
                    <img src="../images/NguyenThiBinh.jpg" alt='Nguyễn Thị Bình in a room with other women.'/>
                    <figcaption>
                        Fig 2 - Photo of Nguyễn Thị Bình Borton, L., 2018. Behind the Scenes, in the Forefront: Vietnamese Women in War and Peace. 
                        ASIANetwork Exchange: A Journal for Asian Studies in the Liberal Arts, 25(1), pp.7–59. DOI: http://doi.org/10.16995/ane.276
                    </figcaption>
                </div>

                <p>
                    Both Minh Mạng and Nguyễn Thị Bình focused on consolidating and centralizing 
                    Vietnam into a singular state in different ways. In both time periods, there was a sharp divide 
                    between the north and the south with the north attempting to gain influence in the south. A 
                    differentiator between the two figures is that Minh Mạng ideologically approached consolidation 
                    from the north and looked down on the south even though he was ironically born in Gia Định
                    which would later be known as Sài Gòn. His goal was in asserting central power over the 
                    south. Nguyễn Thị Bình however stuck to a southern identity and used it extensively to present 
                    the reasons why the south should be a part of the north while simultaneously arguing against the 
                    suffering of the Second Indochina War as previously mentioned. While Minh Mạng escalated 
                    tension in the south, Nguyễn Thị Bình deescalated the tension. She is not the main person behind 
                    the reunification but the reunification under her argument for ending the war in Paris did result in 
                    a significant reduction if not complete end of violence as the American War closed and the 
                    Second Indochina War was ending soon.
                </p>

                <p>
                    Both figures did consolidate Vietnam into a singular state but in different ways and with 
                    different scales of authority. Minh Mạng being the leader of an absolute monarchy had 
                    significantly more power than any singular leader during Nguyễn Thị Bình’s leadership, but his 
                    government would be colonized by the French not long after whereas Vietnam under the same government 
                    Nguyễn Thị Bình led is stable 20 years after she was vice president between 1992
                    and 2002. In the end, her policies and goals being built on empathy relieved tensions in places 
                    where tensions were built by Minh Mạng which led to an acceptance of the consolidation that 
                    wasn’t observed in the same way by people of Minh Mạng’s time. Of course, she was coming off 
                    of a much more violent war than the revolts against Minh Mạng’s policies so there is more 
                    complexity to the situation than can be summarized her but the final result was the end of the war 
                    and the creation of a singular state that has lasted unlike Minh Mạng’s, proving that the efforts of 
                    Nguyễn Thị Bình among other leaders resulted in a more effective nationalist policies.
                </p>

                <h3>Bibliography</h3>

                <p>
                    Goscha, Vietnam: A New History. New York: Basic Books, Hachette Book Group, Inc. 2016.
                </p>

                <p>
                    “Minh Mạng,” Wikipedia. Accessed Oct 10th, 2021. 
                    <br/>
                    https://en.wikipedia.org/wiki/Minh_M%E1%BA%A1ng
                </p>

                <p>
                    “Nguyễn Thị Bình,” Wikipedia. Accessed Oct 10th, 2021. 
                    <br/>
                    https://en.wikipedia.org/wiki/Nguy%E1%BB%85n_Th%E1%BB%8B_B%C3%ACnh
                </p>

                <p>
                    Whitefield, Danny J. Historical and Cultural Dictionary of Vietnam. Metuchen, New Jersey: 
                    <br/>
                    The Scarecrow Press, Inc. 1976.
                </p>

                <p>
                    Wook, Choi Byung. Southern Vietnam under the Reign of Minh Mang (1820-1841), Central 
                    <br/>
                    Policies and Local Response. Ithaca, New York: Cornell University, 2004.
                </p>

                <p>
                    Wu, Judy Tzu-Chun. Radicals on the Road, Internationalism, Orientalism, and Feminism during 
                    <br/>
                    the Vietnam Era. Ithaca, New York and London, England: Cornell University Press, 2013.
                </p>

            </div>

            <div className="center-div">
                <button>View Comments (This is just a test feature, not actual comments)</button>
            </div>


            <div className="center-div">
                <p>If you wish to return to the orignal portfolio page, feel free to use the links in the footer to return to that section of the website</p>
            </div>

        </article>
    )
}