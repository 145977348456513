// Import React
import React from 'react';

export default function Review() {
    return (
        <article className="content app-body">
        
            <h1 className="center-div heading">Man in the High Castle - Book "Review" with Forrest Macneil</h1>

            <div className="center-div">
                <figcaption>
                    <a href="https://en.wikipedia.org/wiki/Review_(TV_series)#Starring" rel="noreferrer" target="_blank">Forrest Macneil</a> | 
                    Oct 28, 2022 | Updated Feb 25, 2023
                </figcaption>
            </div>

            <div className="center-div">
                <p>
                    Life, it’s all we have, but is it any good, is what I would say if this were one of my classic reviews. 
                    “Review” was a wonderful show, but I had to move on to bigger and better things following the conclusion of the third season. 
                    But, I have a new show called “Book Review,” which will hopefully be a more quaint and calm show that really gets my neurons 
                    firing. I am your host, Forrest MacNeil. Join me as we begin this brand-new season of a brand-new show.
                </p>
                
                <p>
                    "Well put Forrest, Cohost," A.J. Gibbs says.
                </p>

                <p>
                    "Thank you A.J. Let’s get started then. Where is our first request from?" Forrest asks.
                </p>

                <p>
                    "We have one from San Francisco coming up on the screen now," A.J. says.
                </p>

                <p>
                    The video feed comes onto the screen. There is an angry ramble in German by a man wearing a black trench coat.
                </p>

                <p>
                    I cock my head to the side and say, I don’t quite understand, German I think. Do we have a translation?
                </p>

                <p>
                    "We do. He said some quite unsavory things, but it is basically a request to review The Grasshopper Lies Heavy, requested by 
                    Freiherr Hugo Reiss, German consul," A.J. says.
                </p>

                <p>
                    "That name sounds familiar. Have they been on the show before?" Forrest asks.
                </p>

                <p>
                    A.J. shakes her head.
                </p>

                <p>
                    Doesn’t ring a bell to you either?
                </p>

                <p>
                    She shakes her head again and says, Never heard of them.
                </p>

                <p>
                    Huh, a little odd that the name is familiar somehow but yes, I will review The Grasshopper Lies Heavy. 
                    Let the new show begin.
                </p>

                <p>
                    <i>Stage lights dim. Roll footage from the review period.</i>
                </p>

                <p>
                    After getting the request from someone I could have sworn was a role player, I turned to the first page of Grasshopper Lies Heavy. 
                    I immediately encountered complications when reading in an antique store in San Francisco because some pipsqueak named 
                    Childan was having a meeting with a Mr. Tagomi. They were very loud and I couldn’t help but eavesdrop on them mentioning 
                    the deals they had in the past with classic American art sales and how Russia somehow collapsed in 1941 during the first 
                    waves of WWII, apparently only 15 years ago. They even mentioned a mysterious figure named Frank Fink used to fight as an 
                    American soldier during WWII and the US lost. All of it sounded a bit strange but alas I held strong to my review of 
                    Grasshopper Lies Heavy.
                </p>
            
                <p>
                    Grasshopper Lies Heavy was a fascinating book about the Second World War and was historically accurate in the early pages 
                    from what I read up until I was writing my notes for this section.
                </p>
            
                <p>
                    <i>Excuse me, editor, play some b roll film of the classic American soldier with a tommy gun marching.</i>
                </p>

                <p>
                    I got up to the section where the allied powers won the war when I was interrupted by a news broadcast on street tv displays 
                    in San Francisco. Hitler was apparently still alive, old and ill. Strange. It was also mentioned that a Nazi rocket ship 
                    heading from Berlin to New York. A strangely haunting and magnificent technology. The news turned to a remembrance of the 
                    assassinated FDR. I scowled but ultimately returned to Grasshopper Lies Heavy.
                </p>

                <p>
                    I seemed to have lost my place because I was then reading about the allied navies protecting Australia and the Philippines 
                    from the Imperial Japanese navy during the war. Must have went back a couple pages on accident. Still, a fascinating endeavor 
                    to be sure. Though, it has slipped my mind that I didn’t even know what genre I was reading in. I turned to the cover and saw 
                    that it was an alternate history book, which is strange because it was just describing real history.
                </p>

                <p>
                    A woman named Juliana was reading the same book at a gas station. I didn’t quite want to ask her what she thought of the book 
                    since me asking another reader would taint my review. Instead, I didn’t speak to her and stuck to gathering my own first 
                    impressions. We both read the book while sitting on a bench in the shade of an oak tree when a man who announced himself 
                    as Joe Cinnadella claimed the book was banned and questions why we had it. Juliana answered all the questions, describing 
                    how interesting the book was for portraying a completely different universe from our own. I couldn’t help but ponder what she 
                    could have meant since Grasshopper Lies Heavy appeared to be an ordinary history book for me. I couldn’t possibly imagine what 
                    made it radically different for Juliana.
                </p>

                <p>
                    I turned to the cover again and took a look at the author’s pen name, Man in the High Castle. What man and what high castle, 
                    I do not know, but I swear that pen name is the name of a book I’ve heard of, a very famous book I believe.
                </p>

                <p>
                    Juliana and Joe took off and I was left pondering why everything felt a little twisted lately. The real history was being 
                    talked about so strangely while some strange things I had never heard of before were treated as normal. It was though I 
                    was in an alternat history story that focused on a book about the real world being pursued and suppressed for its depiction 
                    of a different world order.
                </p>

                <p>
                    That couldn’t possibly be true. Grasshopper Lies Heavy is just an ordinary book, nothing special about it at all.
                </p>

                <p>
                    <i>
                        My producer, no longer Grant, threw on a note that I hit the nail on the head with describing the purpose of Grasshopper 
                        Lies Heavy.
                    </i>
                </p>

                <p>
                    I headed back to the antique store the following day for some more reading. It was quieter. I turned to the next page of the book. 
                    Immediately, Childan questioned aloud if Grasshopper Lies Heavy has a portrayal of the Cold War between the US and Russia. 
                    I too was curious and skip ahead to the Cold War section. The portrayal is of the US and British Empire as the two 
                    superpowers in the Cold War. It is portrayed as the grand finale of the American Revolution, the old colony and the old 
                    empire head-to-head for the confrontation that will decide which Anglo power will become the hegemon of the world. 
                    This may be the section where it is completely different from our own world as Juliana was hinting at. There was also a 
                    section in which the Chinese Nationalist Party under Chiang Kai-shek beats the Communist Party. That was probably why the 
                    book was banned, probably banned in China.
                </p>

                <p>
                    <i>Note from my editor, Grasshopper Lies Heavy isn’t our real world, but it is very similar to our own world, or is it?</i>
                </p>

                <p>
                    I closed the book and caught a train to Denver, not even realizing that several weeks had passed since I began this book review. 
                    My handy pocket radio was put to good use during the long ride. On it, I heard of a planned German bombardment of the 
                    Japanese home island of Honshu if the Japanese Empire didn’t turn over all of the US to Germany. Operation Dandelion they called 
                    it. Another strange thing to say on the radio. Maybe it was another alternate history broadcast to scare listeners like the War 
                    of the Worlds broadcast by Orwell. Can’t fool me radio, I’m smarter than that.
                </p>

                <p>
                    My train arrived in Denver around the early evening. I headed to the supposed high castle a man lived in. The author turns out 
                    was Hawthorne Abendsen. He invited me into the home, had a drink with him and chatted about the book, told him that I was 
                    doing a review and he said he greatly appreciated that I was willing to do something so brave as to review a banned book. 
                    I told him that it was an honor to review anything my viewers request, even life itself.
                </p>

                <p>
                    An hour later, Juliana arrived and demanded to know how Hawthorne Abendsen was able to write a alternate history book that was 
                    about another real world. I was utterly confused at first. How was it possible that she could have known that an alternate 
                    history book was set in a real world, let alone, how did I enter a supposedly separate world from the one I was reading. Then, 
                    I placed the puzzle pieces together that the German and Japanese Empires ruling this world and the world I know being accurately 
                    depicted in a novel described as alternate history meant that I was indeed living within another world in which Grasshopper 
                    Lies Heavy is my world. A totally impossible conclusion that doesn’t make any sense, I know, but that was just how enthralling 
                    the book was. I really felt as though Juliana was aware of my real world, that Grasshopper Lies Heavy was aware of my real 
                    world, that the author Abendsen had been to my real world, but that just wasn’t quite there because the prediction that the 
                    US and British Empire would in a Cold War wasn’t true, from my time point.
                </p>

                <p>
                    <i>End of review footage. Turn to face the camera. Return to studio cameras.</i>
                </p>

                <br/>

                <p>
                    Audience, I am aware that the world Grasshopper Lies Heavy isn’t exactly our real world and that is because it was written in 
                    1962, the same year Man in the High Castle was written and takes place in. The author couldn’t have foreseen that the US and 
                    Soviet Union were so clearly the superpowers in the midst of the Cold War. The book, both of them, are meant to be mirrors in 
                    which both worlds are just as real as the other. Grasshopper Lies Heavy is as close to our world as you were going to get in 
                    the 1960s because the hindsight of what the Cold War looked like wasn’t as clear as current day in 2014, of which my review 
                    may be outdated at some point, but for the time, this was as accurate as you were going to get. Grasshopper Lies Heavy, truly 
                    was our real world and Juliana some how know that, meaning she had left the realm of Man in the High Castle and saw our world. 
                    Same with the author of course, of which the author having the pen name Man in the High Castle, you know its Philip K. Dick, 
                    I don’t see it being any other way.
                </p>

                <p>
                    <i>Approach the stand on the stage with Grasshopper Lies heavy.</i>
                </p>

                <p>
                    But this review isn’t about Man in the High Castle though my thoughts are the same with that book as well. 
                    The books were fantastic of course for having interesting characters, an intriguing plot, you know all the typical 
                    reviewer type stuff, but I want to exclusively talk about the meta. Both books rely on the other being perceived as the 
                    real world and at the time they were the most accurate real-world predictions you were going to get in my observations. 
                    You can’t really just read the books as fun novels because you are aware that some of the characters know about the real 
                    world of the reader and vice versa, the few readers who do pick up the book are aware of the real world of the characters. 
                    The paper is the portal between the worlds for both worlds and Philip K. Dick as the Man in the High Castle is alive in both 
                    worlds.
                </p>

                <p>
                    Beyond the meta portal jumping fiasco you may imagine, I found that the book helped me find a greater appreciation for our 
                    real history. Alternate history pokes at the few fragile, consequential moments in history that make all the difference 
                    between dictators rising and empires crumbling. We can all appreciate that our history is our history and we must remember it 
                    and preserve it.
                </p>

                <p>
                    A.J. Gibbs claps and says, "What an excellent first review."
                </p>

                <p>
                    "Thank you. Thank you so much A.J. I’m happy I can start our next show together," Forrest says.
                </p>

                <p>
                    "Same to you. Next week, we are reviewing Man in the High Castle," A.J. Gibbs says.
                </p>

                <p>
                    Forrest cocks his head to the side.
                </p>

                <p>
                    "That should make for an interesting read seeing how connected it is to Grasshopper Lies Heavy," Forrest says.
                </p>

                <p>
                    <i>Turns to the camera.</i>
                </p>

                <p>
                    "Catch us next week folks for our second episode of Book Review. Forrest MacNeil, out."
                </p>        
                
                <figcaption>
                    This review was written from the perspective of the fiction character Forrest Macneil from the television series Review. 
                    Actual creative work of this review was by Donovan Frazier.
                </figcaption>

            </div>

            <div className="center-div">
                <button>View Comments (This is just a test feature, not actual comments)</button>
            </div>

            
            <div className="center-div">
                <p>If you wish to return to the orignal portfolio page, feel free to use the links in the footer to return to that section of the website</p>
            </div>

        </article>
    )
}