// Import React
import React from 'react';

export default function Janissaries() {
    return (
        <article className="content app-body">
        
            <h1 className="center-div heading">Corruption of the Janissaries</h1>

            <div className="center-div">
                <img 
                    className='article-img' 
                    src="../images/janissaries.jpg" 
                    alt='Three janissary soldiers standing aboard a ship' 
                />

                <figcaption>
                    Janissaries standing aboard a ship in <a 
                        href="https://www.realmofhistory.com/2022/04/19/facts-ottoman-janissaries/" 
                        rel='noreferrer' 
                        target='_blank'
                        >brightly colored
                    </a> attire.
                </figcaption>
            </div>

            <div class="center-div">
                <figcaption>
                    <a href="../index.html">Donovan Frazier</a> | Nov 26, 2022 | Updated Feb 25, 2023
                </figcaption>
            </div>

            <div className="center-div">
                <p>
                    The janissaries were the elite fighting ground forces of the Ottoman Empire from around 
                    the time of their founding to the time of their dissolution in 1826. Their primary role in the 
                    empire was to serve the sultan during his conquests of new borderlands. Their code of conduct 
                    was written to ensure their absolute obedience to the sultan. They were extraordinarily effective 
                    at this in their early days as seen with the conquest of Constantinople, now Istanbul, and other 
                    border territories in Eastern Europe and North Africa. Much of their success came from being the 
                    largest standing army in the territories they invaded and their use of gunpowder weapons such as 
                    rifles and cannons.
                    <sup>[<a href="http://www.jstor.org/stable/23906961" rel='noreferrer' target="_blank">1</a>]</sup> 
                    Being a large and highly armed army gave the janissaries a unique status as a 
                    formidable force. Towards the end of their utilization, the janissaries as with any people found 
                    ways to erode their original purpose and defy their code for personal gain. Several sultans had 
                    expressed interest in replacing the janissaries because of the janissaries’ corruption and 
                    disobedience which halted further reforms of the empire. Despite the strict codes and harsh 
                    punishments for disloyalty to the sultan, the janissaries had become a hinderance to the empire 
                    modernizing in a way the sultans’ were seeking rather than a tool for the sultans. They were 
                    disbanded officially by Sultan Mahmud II in 1826 in an event that was known as the “Auspicious Event.”
                    <sup>[<a href="http://www.jstor.org/stable/10.3366/j.ctv2f4vjf5.6" rel='noreferrer' target="_blank">2</a>]</sup> 
                    The corruption of the janissaries was the primary cause of sultans seeking to replace 
                    them and established a new army that was perceived as more loyal.
                </p>

                <figcaption>
                    [1] Dorsey D. Jones, “THE JANISSARIES—UNIQUE FIGHTING MEN.” Social Science 6, no. 4 (1931): 399,
                    http://www.jstor.org/stable/23906961.
                </figcaption>

                    <br/>

                <figcaption>
                    [2] Darin N. Stephanov, “The First Shift in (Modern) Ruler Visibility: The Reign of Mahmud II (1808–39),” in Ruler 
                    Visibility and Popular Belonging in the Ottoman Empire, 1808-1908. (Edinburgh: Edinburgh University Press, 
                    2019) 16. http://www.jstor.org/stable/10.3366/j.ctv2f4vjf5.6.
                </figcaption>

                    <br/>

                <p>
                    The original purpose of the janissaries was to have absolute obedience to the Sultan.
                    From the time the janissaries were young boys, voynuks, Ottoman agents for the sultan, were 
                    sent to the Christian borderlands.
                    <sup>[<a href="http://www.jstor.org/stable/27696448" rel='noreferrer' target="_blank">3</a>]</sup> 
                    The boys would be taken and enslaved directly to the sultan as 
                    a means to have absolute loyalty to him alone. They were not supposed to have loyalty to anyone 
                    else in the empire including their own families in the borderlands. Unlike ordinary slaves that 
                    were emancipated after a decade of servitude, the janissaries were never emancipated.
                    <sup>[<a href="http://www.jstor.org/stable/j.ctt6wp6pg.7" rel='noreferrer' target="_blank">4</a>]</sup> 
                    They were owned by the sultan for their entire lives. In exchange, they were compensated in silver and 
                    were provided military privileges such as the ability to bear weapons in both times of peace and 
                    war. They would receive education in and converted to Islam. This was meant to spread Islam 
                    for the empire.
                    <sup>[5]</sup> 
                    The youth were also training in combat, sometimes at the palace and other times 
                    at Gallipoli. After training, they wore brightly colored clothing and a lion insignia to demonstrate 
                    that they were janissaries. Additionally, they had color coded boots to display their ranks. 
                    Janissaries wore red while their officers wore black.
                    <sup>[<a href="https://doi.org/10.1080/03071849309417737" rel='noreferrer' target="_blank">6</a>]</sup> 
                    They were then permanently or temporarily 
                    assigned to barracks in major cities for the defense of those cities. Alternatively, they could be 
                    called on by the sultan for a conquest of the Christian borderlands. The janissaries were well 
                    organized and trained since their youth for the purpose of serving the sultan.
                </p>

                <figcaption>
                    [3] Evgeni Radushev, “‘Peasant’ Janissaries?,” Journal of Social History 42, no. 2 (2008): 447,
                    http://www.jstor.org/stable/27696448.
                </figcaption>

                     <br/>
                
                <figcaption>
                    [4] Gilles Veinstein, “On the Ottoman Janissaries (Fourteenth-Nineteenth Centuries),” in Fighting for a Living: A 
                    Comparative Study of Military Labour 1500-2000. Edited by Erik-Jan Zürcher (Amsterdam: Amsterdam University 
                    Press, 2013), 126. http://www.jstor.org/stable/j.ctt6wp6pg.7 
                </figcaption>

                    <br/>
                
                <figcaption>
                    [5] Radushev, “’Peasant’ Janissaries?,” 452. 
                </figcaption>

                    <br/>

                <figcaption>[6] Major General F.H. Tyrrell, “The Turkish Janissaries,” Royal United Services Institution 37, no. 180 (2009): 141, 
                    https://doi.org/10.1080/03071849309417737. 
                </figcaption>

                    <br/>

                <p>
                    Though the janissaries were primarily Christian children in the borderlands of the empire, 
                    there were a couple of ways other individuals could become janissaries. One way was that the 
                    irregular fighting forces who would normally wear yellow boots and weren’t as prestigious as the 
                    janissaries could be promoted to become a janissary.
                    <sup>[7]</sup> 
                    It may sound counterintuitive for a lower 
                    ranking soldier to be promoted into an enslavement but for the Ottoman Empire, serving the 
                    sultan as a slave was a prestigious position that was considered higher in the social structure than 
                    an ordinary free peasant. There was no description of how old one of these irregular soldiers had 
                    to be to be promoted into a janissary. This breaks the mold that janissaries had to be educated 
                    from a very young age to be loyal to the sultan as the standard soldiers promoted to janissaries 
                    could have a vastly different upbringing from the regular janissaries with possible implications.
                </p>

                <figcaption>
                    [7] Major General F.H. Tyrrell, “The Turkish Janissaries,” 143.
                </figcaption>

                <br/>

                <p>
                    Another means for other individuals to become janissaries was when the sons of 
                    janissaries were allowed to enter the ranks of the father janissaries.
                    <sup>[8]</sup> 
                    The original purpose of this 
                    was to have the fighting forces of the janissaries continue recruiting their own replacements 
                    rather than having solely children captured in the borderlands. This may have contributed to the 
                    increase in size of the janissary corps
                    <sup>[9]</sup>
                    however there are other implications of this system that 
                    will be explored later but this system was meant to uphold the loyalty of the janissaries. 
                    Betraying the sultan would mean betraying your family as well.
                </p>

                <figcaption>
                    [8] Major General F.H. Tyrrell, “The Turkish Janissaries,” 143.
                </figcaption>

                <br/>

                <figcaption>
                    [9] Gilles Veinstein, “On the Ottoman Janissaries (Fourteenth-Nineteenth Centuries),” 120.
                </figcaption>

                <br/>

                <p>
                    To go against the sultan meant going against the very purpose and origin of the 
                    janissaries. In their 14-point code of conduct, the first code is to have absolute obedience to the
                    sultan.
                    <sup>[10]</sup> 
                    Breaking this code or any other code resulted in punishments by the officers that ranged 
                    from a couple days in jail all the way up to execution by decapitation in the sultan’s palace in 
                    Istanbul. Execution was the highest punishment and was accompanied by a rifle being shot from 
                    the palace to signify to the other janissaries in the barracks that the punishment had been delt.
                    <sup>[11]</sup>
                    The janissaries had very short and clear codes for them to be obedient to the sultan, enslavement, 
                    an upbringing from childhood to serve the sultan, and harsh punishments for their betrayal. They 
                    were supposed to be the most loyal, brave, and selfless fighting force possible.
                </p>

                <figcaption>
                    [10] Major General F.H. Tyrrell, “The Turkish Janissaries,” 139.
                </figcaption>

                <br/>

                <figcaption>
                    [11] Major General F.H. Tyrrell, “The Turkish Janissaries,” 139.
                </figcaption>

                <br/>

                <p>
                    All of the factors listed above did not stop the janissaries from eventually becoming 
                    corrupt. In spite of the harsh punishments for disobeying their code of conduct, the janissaries 
                    still in mass defied their codes for personal gain in a complete contrast of how they were 
                    intended to behave. The janissaries found loopholes in their codes and even betraying the sultan 
                    in regicide.
                </p>

                <p>
                    As mentioned before, the janissaries were allowed to have their sons join their units and 
                    become janissaries as well. This system may have been intended for the janissaries to have 
                    tighter bonds with their units to maintain loyalty to each other as well as the sultan. This wasn’t 
                    always the case as the janissaries at times listed their son on the unit rosters when the sons were 
                    still infants.
                    <sup>[12]</sup> 
                    The fathers would collect their pay and the pay intended for the son. In effect, the 
                    janissaries were double dipping into the funds of the empire. This shows that the janissaries were 
                    capable and willing to exploit loopholes in their code.
                </p>

                <figcaption>
                    [12] Major General F.H. Tyrrell, “The Turkish Janissaries,” 144.
                </figcaption>

                <br/>

                <p>
                    This of course wasn’t the only code that janissaries broke. One such code was that 
                    janissaries weren’t allowed to pursue a trade. They were strictly a professional military force, not 
                    meant to take part in civilian affairs. This was not upheld as the janissaries often took civilian 
                    jobs outside of their main duties. In Istanbul for example, some janissaries used their spare time 
                    to run coffee houses.
                    <sup>[13]</sup> 
                    Jobs like these were completely outside of the military duties of the janissaries.
                </p>

                <figcaption>
                    [13] Dorsey D. Jones, “THE JANISSARIES—UNIQUE FIGHTING MEN,” 400.
                </figcaption>

                <br/>

                <p>
                    Another code that the janissaries broke was the mandate that they must live inside their 
                    barracks exclusively. They didn’t always do this. In the later days of their existence, the 
                    janissaries often married lived outside of the barracks in exchange for not having access to 
                    rations.
                    <sup>[14]</sup> 
                    The janissaries even owned land and cultivated that land. Both working the coffee 
                    houses and owning land
                    <sup>[15]</sup> 
                    demonstrated that the janissaries had a variety of interests outside of
                    their military duties that they were able to pursue without punishment as their code enforcement 
                    relaxed.
                </p>

                <figcaption>
                    [14] Major General F.H. Tyrrell, “The Turkish Janissaries,” 144.
                </figcaption>

                <br/>

                <figcaption>
                    [15] Evgeni Radushev, “‘Peasant’ Janissaries?,” 454.
                </figcaption>

                <br/>

                <p>
                    The above breaks in the code were the beginning of the corruption. In the later days of 
                    the janissaries, the sultans were concerned with imperial competition with the rising powers such 
                    as Russia and the British Empire. The sultans pursued reforms for the empire including 
                    modernizing the military using the advice of French advisors
                    <sup>[<a href="http://www.jstor.org/stable/4282890" rel='noreferrer' target="_blank">16</a>]</sup> 
                    which was approved by the ruling 
                    elite but the janissaries opposed reforms and successfully established a political voice over the 
                    sultan. Sultans were threatened for imposing such reforms. The janissaries were now being seen 
                    as a hostage holder faction, dictating what the sultans should do.
                </p>

                <figcaption>
                    [16] Avigdor Levy, “Military Reform and the Problem of Centralization in the Ottoman Empire in the Eighteenth 
                    Century,” Middle Eastern Studies 18, no. 3 (1982): 236, http://www.jstor.org/stable/4282890.
                </figcaption>

                    <br/>

                <p>
                    It is highly unlikely that all janissaries universally embraced the corruption. There are 
                    likely some janissaries that continued to uphold their codes while others attempted to break their 
                    codes the first chance they had. There is nuance to exactly how many janissaries followed each 
                    of these positions but the janissary corps were called into question by the sultans. Sultan Selim 
                    III viewed the janissaries as the main obstacle holding him back from enacting reforms for 
                    modernization.
                    <sup>[17]</sup> 
                    His solution was to form a replacement army and disband the current 
                    janissaries. By having a different army, he would avoid the janissaries possibly reacting violently 
                    to his reforms. This didn’t work as he may have expected. A new army of about 20,000 soldiers 
                    began being trained in 1807.
                    <sup>[18]</sup> 
                    The sultan also demanded that the janissaries train and drill on a 
                    regular basis. The sultan’s unit was known as the “New Order.” The purpose of this unit was to 
                    establish another military unit directly loyal to the sultan once again as a revival of older checks 
                    and balances in the government. This was a direct source of competition to the position of the 
                    janissaries. The janissaries of the palace rebelled and assassinated the sultan.
                </p>

                <figcaption>
                    [17] Avigdor Levy, “Military Reform and the Problem of Centralization in the Ottoman Empire in the Eighteenth 
                    Century,” 239.
                </figcaption>

                    <br/>

                <figcaption>
                    [18] Avigdor Levy, “Military Reform and the Problem of Centralization in the Ottoman Empire in the Eighteenth 
                    Century,” 239.
                </figcaption>

                    <br/>

                <p>
                    At this point, the janissaries were in direct opposition to their original purpose, entirely 
                    rejecting their loyalty to the sultan when faced with a replacement army. They demonstrated that 
                    they collectively could enact a regicide. The sultans of the 1700s that could roam the streets of Istanbul freely
                    <sup>[19]</sup> 
                    may never have imagined that their prestigious and most loyal fighting force a 
                    hundred years later would betray a sultan.
                </p>

                <figcaption>
                    [19]  Darin N. Stephanov, “The First Shift in (Modern) Ruler Visibility: The Reign of Mahmud II (1808–39),” 13.
                </figcaption>

                <br/>

                <p>
                    The janissaries were again seen as a burden, hinderance, and an active threat by later 
                    sultan, Mahmud II. At this point in time of the early 1800s, the janissaries were no longer 
                    fulfilling their purpose in the empire. They were meant to expand the empire through the 
                    conquest of border territories. As already mentioned, they were highly effective at this for the 
                    first several centuries of their existence as an army because of their skill with gunpowder 
                    weapons such as rifles and cannons as early as 1514.
                    <sup>[20]</sup>
                    They successfully laid siege to walled 
                    cities and destroyed such walls which was a seemingly impossible task for previous armies 
                    before gunpowder. In the later centuries, they were no longer as effective. Their army was no 
                    longer able to rapidly expand the empire and instead, the empire was ceding territory to 
                    bordering empires such as Russia and western colonial empires such as France. This was also in 
                    spite of the janissary corps growing from the original 20,000 janissaries that fought with Sultan 
                    Süleyman all the way to 135,000 janissaries under Sultan Mahmud II’s reign at the time of their 
                    disbanding.
                    <sup>[<a href="https://en.wikisource.org/wiki/Page%3AEB1911_-_Volume_15.djvu/167" rel='noreferrer' target="_blank">21</a>]</sup> 
                    The fighting force was exponentially larger but wasn’t nearly as successful as it 
                    had once been.
                </p>

                <figcaption>
                    [20] Dorsey D. Jones, “THE JANISSARIES—UNIQUE FIGHTING MEN,” 401.
                </figcaption>

                <br/>

                <figcaption>
                    [21] “Janissaries,” 1911 Encyclopedia Britannica, vol. 15, 151. https://en.wikisource.org/wiki/Page%3AEB1911_-_Volume_15.djvu/167.
                </figcaption>

                <br/>

                <p>
                    s a fighting force, the janissaries had lost their luster, were no longer seen as loyal to the 
                    sultans, and opposed reforms. Their very lion insignia was now seen as a symbol of unrest and insubordination.<sup>[22]</sup> 
                    Even the most important code for the janissaries, absolute obedience to the 
                    sultan with a death penalty being a response for betrayal, was still broken when the opportunity 
                    was presented.
                </p>

                <figcaption>
                    [22] Howard A. Reed, “The Destruction of the Janissaries in 1826; how Sultan Mahmud II Abolished the Corps,” in 
                    Defensive Mobilization: The Military-Administrative Phase. (Princeton University, transcript of Ph.D. dissertation, 
                    1951) 36.
                </figcaption>

                    <br/>

                <p>
                    The janissaries after the assassination of Sultan Selim III were seen as a terrorizing force 
                    and active threat to Sultan Mahmud II. At times, he hid in the chimney of baths to avoid 
                    janissary assassins.
                    <sup>[23]</sup> 
                    This experience may have displayed to Sultan Mahmud II that abolishing 
                    the janissaries was a necessity and that he had to do so in a way in which he was still protected.
                </p>

                <figcaption>
                    [23] Darin N. Stephanov, “The First Shift in (Modern) Ruler Visibility: The Reign of Mahmud II (1808–39),” 15.
                </figcaption>

                <br/>

                <p>
                    Two decades after Sultan Selim III had attempted to replace the janissaries, Sultan 
                    Mahmud II had successfully done so. He made a firman that the janissaries were to be disbanded 
                    and called on “True Muslims” to come to his aid.
                    <sup>[24]</sup> 
                    He had established a religious army to 
                    replace the secular janissaries and when he abolished the janissaries on the “Auspicious Event,” 
                    the new army defended him from the opposing janissaries. The janissaries reacted to the news of 
                    the new army with riots in the streets that burned down houses. The janissaries attempted to rush 
                    the palace and capture the sultan but never made their way in because of the new army.<sup>[25]</sup> 
                    Within a week of their disbanding, 2,000 soldiers of the new army trained regularly at the front of the 
                    Topkapi palace.
                    <sup>[26]</sup> 
                    The janissaries were fast replaced by a new army that was capable of keeping them at bay.
                </p>

                <figcaption>
                    [24] Dorsey D. Jones, “THE JANISSARIES—UNIQUE FIGHTING MEN,” 401
                </figcaption>

                <br/>

                <figcaption>
                    [25] Howard A. Reed, “The Destruction of the Janissaries,” 35.
                </figcaption>

                <br/>

                <figcaption>
                    [26] Major General F.H. Tyrrell, “The Turkish Janissaries,” 145.
                </figcaption>

                <br/>

                <p>
                    The cause of the disloyalty of the janissaries may be assumed to be because some of the 
                    janissaries were promoted into the ranks after having already lived free. Those janissaries had 
                    lives beyond the janissary corps by the time they were promoted into the janissary role. This may 
                    lead to the idea that those janissaries introduced the disloyalty that would eventually lead to the 
                    janissaries being disbanded. This may not be the most accurate cause of janissary disloyalty. 
                    Instead, the janissaries expressed self-interest and agency to pursue those interests. They were 
                    still slaves of the sultan and expressed a strive for personal interests. As their codes relaxed, they 
                    were better able to pursue those interests. The act of taking away the freedom to pursue those 
                    interests as seen with Sultan Selim III demanding the janissaries train more resulted in violent 
                    reaction as the janissaries used their very tools to hold power over the sultans. The act of taking 
                    away the gradual freedoms of the janissaries is the real cause of their disloyalty.
                </p>

                <p>
                    The corruption of the janissaries was the primary cause of the later sultans seeking to 
                    replace them, but that corruption was created by the janissaries seeking more freedoms from 
                    their codes. The janissaries exploited their codes such as doubling their pay illegally but also 
                    pursues personal interests such as owning coffee houses. The sultans retracting these slowly 
                    gained freedoms resulted in further escalation of the corruption as seen with the assassination of 
                    Selim III and the terrorism aimed at Mahmud II. The system of the janissary corps lacking 
                    freedom for the janissaries directly contributed to the corruption and abolition of the janissaries.
                </p>

            </div>

            <div class="center-div">
                <button>View Comments (This is just a test feature, not actual comments)</button>
            </div>

            <div class="center-div">
                <p>If you wish to return to the orignal portfolio page, feel free to use the links in the footer to return to that section of the website</p>
            </div>

        </article>
    );
}